<template>
  <b-card>
    <b-card-text class="mx-2"> Add/Edit Cost Sheet for Inquiry - <u>{{ inquiryId }}</u></b-card-text>
    
          <validation-observer ref="infraCostSheetRules">
            <b-form @submit.prevent>
              <b-row>
              <b-col md="6">
              <b-form-group label="Sheet Date" label-for="sheet-date">
                <validation-provider #default="{ errors }" name="Sheet Date" rules="required">
                  <b-form-input
                    id="sheet-date"
                    v-model="form.sheet_date"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Sheet Date"
                    type="date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Client" label-for="client">
                <validation-provider #default="{ errors }" name="Client" rules="required">
                  <v-select
                    id="client"
                    v-model="form.client"
                    :options="clientList"
                    label="title"
                    :reduce="(option) => option.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select Account/Client Name"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Position" label-for="position">
                <validation-provider #default="{ errors }" name="Position" rules="required">
                  <!-- <b-form-input
                    id="position"
                    v-model="form.position"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Position"
                  /> -->
                  <v-select
                    id="position"
                    v-model="form.position"
                    :options="positionList"
                    label="title"
                    :reduce="(option) => option.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select Position/Requirement"
                    push-tags
                    taggable
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Qualification" label-for="qualification">
                <!-- <validation-provider #default="{ errors }" name="Qualification"> -->
                  <b-form-input
                    id="qualification"
                    v-model="form.qualification"
                    
                    placeholder="Enter Qualification"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Years of Experience" label-for="years-of-experience">
                <validation-provider #default="{ errors }" name="Years of Experience" rules="required">
                  <b-form-input
                    id="years-of-experience"
                    v-model="form.yoe"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Years of Experience"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Current CTC" label-for="current-ctc">
                <validation-provider #default="{ errors }" name="Current CTC" rules="required">
                  <b-form-input
                    id="current-ctc"
                    v-model="form.current_ctc"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Current CTC"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group :label="'CTC Increment Percent (' + form.current_ctc * form.ctc_increment_percent/100 + ')'"  label-for="ctc-increment-percent">
                <validation-provider #default="{ errors }" name="CTC Increment Percent" rules="required">
                  <b-form-input
                    id="ctc-increment-percent"
                    v-model="form.ctc_increment_percent"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter CTC Increment Percent"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Expected CTC" label-for="expected-ctc">
                <!-- <validation-provider #default="{ errors }" name="Expected CTC"> -->
                  <b-form-input
                    id="expected-ctc"
                    v-model="form.expected_ctc"
                    
                    placeholder="Enter Expected CTC"
                    type="number"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Leave Encashment" label-for="leave-encashment">
                <!-- <validation-provider #default="{ errors }" name="Leave Encashment"> -->
                  <b-form-input
                    id="leave-encashment"
                    v-model="form.leave_encashment"
                    
                    placeholder="Enter Leave Encashment"
                    type="number"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Margin Percent" label-for="margin_percent">
                <validation-provider #default="{ errors }" name="Margin Percent">
                  <b-form-input
                    id="margin-percent"
                    v-model="form.margin_percent"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Margin Percent"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Commercial Cost" label-for="commercial-cost">
                <validation-provider #default="{ errors }" name="Commercial Cost" rules="required">
                  <b-form-input
                    id="commercial-cost"
                    v-model="form.commercial_cost"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Commercial Cost"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Margin" label-for="margin">
                <validation-provider #default="{ errors }" name="Margin" rules="required">
                  <b-form-input
                    id="margin"
                    v-model="form.margin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Margin"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Food and Accommodation Cost" label-for="food-accomodation-cost">
                <validation-provider #default="{ errors }" name="Food and Accommodation Cost" rules="required">
                  <b-form-input
                    id="food-accomodation-cost"
                    v-model="form.food_accomodation_cost"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Food and Accommodation Cost"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Commercial Cost with F&A" label-for="commercial-cost-with-fna">
                <validation-provider #default="{ errors }" name="Commercial Cost with FnA" rules="required">
                  <b-form-input
                    id="commercial-cost-with-fna"
                    v-model="form.commercial_cost_with_fna"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Commercial Cost with F&A"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Man Months" label-for="man-months">
                <!-- <validation-provider #default="{ errors }" name="Man Months"> -->
                  <b-form-input
                    id="man-months"
                    v-model="form.man_months"
                    
                    placeholder="Enter Man Months"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <!-- </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Total Project Commercial Cost" label-for="total-project-commercial-cost">
                <!-- <validation-provider #default="{ errors }" name="Total Project Commercial Cost"> -->
                  <b-form-input
                    id="total-project-commercial-cost"
                    v-model="form.total_project_commercial_cost"
                    placeholder="Enter Total Project Commercial Cost"
                    type="number"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <!-- </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Days" label-for="days">
                <!-- <validation-provider #default="{ errors }" name="Days"> -->
                  <b-form-input
                    id="days"
                    v-model="form.days"
                    
                    placeholder="Enter Days"
                    type="number"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              </b-col>
              <b-col md="6">
              <b-form-group label="Amount Based on Days" label-for="days-amount">
                <!-- <validation-provider #default="{ errors }" name="Amount Based on Days"> -->
                  <b-form-input
                    id="days-amount"
                    v-model="form.days_amount"
                    
                    placeholder="Enter Amount based on days"
                    type="number"
                    step="0.01"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              </b-col>
              </b-row>        
              <b-button type="submit" variant="primary" @click="onSubmit">Submit</b-button>
            </b-form>
  </validation-observer>
  </b-card>
   
</template>

<script>
import {BButton, BCard, BRow, BCol, BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AdminConfigService from '@/services/admin-config-service'
import infraCostSheetService from '@/services/infra-cost-sheet-service';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select';
import { required,double } from '@validations'

export default {
  data() {
    return {
      form: {
        cost_sheet_id: '',
        inquiry_id: '',
        sheet_date: '',
        client: '',
        position: '',
        qualification: '',
        yoe: '',
        current_ctc: '',
        ctc_increment_percent: '',
        expected_ctc: '',
        leave_encashment: '',
        margin_percent: '',
        margin: '',
        commercial_cost: '',
        food_accomodation_cost: '',
        commercial_cost_with_fna: '',
        man_months: '',
        total_project_commercial_cost: 0,
        days: '',
        days_amount: '',
        added_by: '',
        updated_by: '',
      },
      clientList: [],
      positionList: [],
      validatedForm: false,
      role: ''
    }
  },
  components: {
    BButton,
    BCard,  BCardText, BRow, BCol,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    vSelect
  },
  directives: {
   
  },
  props: {
    inquiryId: String,
    client: String
  },
  watch: {
      'form.current_ctc': 'computeExpectedCTC',
      'form.ctc_increment_percent': 'computeExpectedCTC',
      'form.expected_ctc': 'computeLeaveEncashment',
      'form.margin_percent': 'computeCommercialCost',
      'form.commercial_cost': 'computeMargin',
      'form.food_accomodation_cost': 'computeCommercialCostWithFood',
      'form.days': 'computeDaysAmount'
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'));
        const empId = userData.emp_id
        this.role = userData.roles[0].toLowerCase()
        const name = userData.first_name + ' ' + userData.last_name
    this.form.client = this.client
    this.form.inquiry_id = this.inquiryId
    this.form.added_by = {
        title: name,
        empId: empId,
        email: userData.email
    }
    this.form.updated_by = {
        title: name,
        empId: empId,
        email: userData.email
    }
  },
  methods: {
    onSubmit() {
      // Handle form submission
      this.validationForm()
      console.log('Submit form', this.validatedForm)
       if(this.validatedForm) {
        console.log(this.form);
        infraCostSheetService.createCostSheet(this.form).then(res => {
          if(res.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Costsheet added Successfully!!!',
                icon: 'EditIcon',
                variant: 'primary',
                timeout: 3000,
              },
            })
            this.resetData()
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right-cost-sheet-add')
          }
        })
      }
      
      
    },
    validationForm() {
      this.$refs.infraCostSheetRules.validate().then(success => {
        console.log('in validadtion', success)
          if (success) {
          // eslint-disable-next-line
              this.validatedForm = true
          } else {
              alert('Form validation Failed !!!')
          }
          return this.validatedForm
      })
    },
    computeExpectedCTC() {
      const currentCtc = parseFloat(this.form.current_ctc);
      const incrementPercent = parseFloat(this.form.ctc_increment_percent);
      if (!isNaN(currentCtc) && !isNaN(incrementPercent)) {
        this.form.expected_ctc = currentCtc + (currentCtc * incrementPercent / 100);
      } else {
        this.form.expected_ctc = '';
      }
    },
    computeLeaveEncashment() {
      const expectedCTC = parseFloat(this.form.expected_ctc);
      
      if (!isNaN(expectedCTC) ) {
        this.form.leave_encashment = expectedCTC / 30 * 1.5;
      } else {
        this.form.leave_encashment = '';
      }
    },
    computeCommercialCost() {
      const expectedCTC = parseFloat(this.form.expected_ctc);
      const leaveEncashment = parseFloat(this.form.leave_encashment)
      const marginPercent = parseFloat(this.form.margin_percent)
      
      if (!isNaN(expectedCTC) && !isNaN(leaveEncashment) && !isNaN(marginPercent) ) {
        this.form.commercial_cost = ((expectedCTC + leaveEncashment) + (expectedCTC + leaveEncashment) * marginPercent/100);
        
      } else {
        this.form.commercial_cost = '';
      }
    },
    computeMargin() {
      const expectedCTC = parseFloat(this.form.expected_ctc);
      const leaveEncashment = parseFloat(this.form.leave_encashment)
      const commercialCost = parseFloat(this.form.commercial_cost)
      
      if (!isNaN(expectedCTC) && !isNaN(leaveEncashment) && !isNaN(commercialCost) ) {
        this.form.margin = commercialCost - expectedCTC - leaveEncashment;
      } else {
        this.form.margin = '';
      }
    },
    computeCommercialCostWithFood() {
      const commercialCost = parseFloat(this.form.commercial_cost);
      const foodAccomodationCost = parseFloat(this.form.food_accomodation_cost);
      if (!isNaN(foodAccomodationCost) ) {
        this.form.commercial_cost_with_fna = foodAccomodationCost + commercialCost;
      } else {
        this.form.commercial_cost_with_fna = '';
      }
    },
    computeDaysAmount() {
      const commercialCostWithFnA = parseFloat(this.form.commercial_cost_with_fna);
      const days = parseFloat(this.form.days)
      if (!isNaN(commercialCostWithFnA) && !isNaN(days)) {
        this.form.days_amount = parseInt(commercialCostWithFnA / days);
      } else {
        this.form.days_amount = '';
      }
    },
    resetData() {
      this.form = {
        cost_sheet_id: '',
        inquiry_id: '',
        sheet_date: '',
        client: '',
        position: '',
        qualification: '',
        yoe: '',
        current_ctc: '',
        ctc_increment_percent: '',
        expected_ctc: '',
        leave_encashment: '',
        margin_percent: '',
        margin: '',
        commercial_cost: '',
        food_accomodation_cost: '',
        commercial_cost_with_fna: '',
        man_months: '',
        total_project_commercial_cost: 0,
        days: '',
        days_amount: '',
        added_by: '',
        updated_by: '',
      }
    } 
  },
  mounted() {
    AdminConfigService.getConfigInfra().then(res => {
        if (res.status === 'OK'){
            console.log('infra config list: ', res.data)
            this.clientList = res.data.clients;
            // this.models = res.data.models;
            // this.statusOptions = res.data.statusOptions;
            // this.sectors = res.data.sectors
            // this.tatDeputations = res.data.tatDeputations
            // this.projectDurations = res.data.projectDurations
            this.positionList = res.data.positionList
        }
    })

  }
 }
</script>
<style scoped lang="scss">
.text-danger {
  font-size: 0.875rem;
}
.card-body {
    padding: 0.75rem;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>