<template>
    <b-card>
    <app-timeline>
  
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item>
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h4>Infra Inquiry - {{ inquiryId }}</h4> <span class="ml-4"> <router-link :to="{ path: '/infra/inquiry/edit/' + encodeURIComponent(inquiryId) }"><feather-icon size="15" icon="EditIcon" class="text-primary" v-b-tooltip.hover.bottom="'Modify Inquiry'"/>Edit Inquiry/Add New Requirement</router-link> </span>
          <small class="text-muted">{{ formatInquiryDate(inquiryData.createdAt) }} <br> <span v-if="inquiryData.added_by"> {{ inquiryData.added_by.title }} </span></small>
        </div>
        <div class="mb-2">
            <span> Details: </span>
            
        </div>
        
        <b-row >
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Client </span>
                        <h6 class="mb-0">
                            {{ inquiryData.client_name }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Sector </span>
                        <h6 class="mb-0">
                          {{ inquiryData.business_sector }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <!-- <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Position Count </span>
                        <h6 class="mb-0">
                          
                        </h6>
                    </div>
                </div> 
            </b-col> -->
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Model </span>
                        <h6 class="mb-0">
                          {{ inquiryData.commercial_model }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Inquiry Date </span>
                        <h6 class="mb-0">
                          {{ inquiryData.inquiry_date }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Requirement Date </span>
                        <h6 class="mb-0">
                          {{ inquiryData.requirement_date }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Status </span>
                        <h6 class="mb-0">
                          {{ inquiryData.status }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="3">
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <span class="text-muted"> Project Locations </span>
                        <h6 class="mb-0">
                          {{ inquiryData.project_location }}
                        </h6>
                    </div>
                </div> 
            </b-col>
            <b-col md="12">
              <h4 class="mt-1">Position/Requirement List:</h4>
              <div v-if="inquiryData.infra_position_inquiries.length > 0">
                <b-table
                    striped
                    bordered
                    hover
                    responsive
                    :items="inquiryData.infra_position_inquiries"
                    :fields="positionFields"
                    style="width: 100% !important"
                >
                <!-- <template #cell(actions)="data" >
                    <div class="text-nowrap" @click="editPosition(data.item.id)" v-b-tooltip.hover.bottom="'Edit Position'">
                        <feather-icon size="18" icon="EditIcon" class="text-info"/>
                    </div>
                    <div class="text-nowrap" @click="removePosition(data.item.id)" v-b-tooltip.hover.bottom="'Remove Position'">
                        <feather-icon size="18" icon="XIcon" class="text-danger"/>
                    </div>
                </template> -->
                </b-table>
            </div>
            </b-col>    
        </b-row>
        

        
      </app-timeline-item>
  
      <app-timeline-item variant="secondary">
  
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h4>Cost Sheets - SHPL/RiL/CS/18052024/V4</h4> 
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle.sidebar-right-cost-sheet-add
            variant="outline-primary"
          >
            Add New CostSheet
          </b-button>
          <b-sidebar
            id="sidebar-right-cost-sheet-add"
            bg-variant="white"
            right
            backdrop
            shadow
            width="45%"
          >
            <infra-add-cost-sheet-vue :inquiry-id="inquiryId" :client="inquiryData.client_name"/>
          </b-sidebar>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle.sidebar-right-cost-sheet-edit
            variant="outline-primary"
          >
            Edit CostSheet
          </b-button> -->
          <b-sidebar
            id="sidebar-right-cost-sheet-edit"
            bg-variant="white"
            right
            backdrop
            shadow
            width="45%"
          >
            <infra-edit-cost-sheet-vue :inquiry-id="inquiryId" :client="inquiryData.client_name"/>
          </b-sidebar>
          <small class="text-muted">45 min ago</small>
        </div>
        <div v-if="approvedCostSheet">
          <p>Approved Cost Sheet:</p>
          
          <b-table :items="approvedCostSheet" :fields="costSheetFields" bordered responsive="sm" style="overflow: auto;">
          </b-table>
        </div>
        <b-button
          @click="showPreviousVerions"
          size="sm"
          variant="outline-primary"
          class="mb-2"
        >
          
        Show Previous Versions
        </b-button>
        <b-table :items="costSheets" :fields="costSheetFields" responsive="sm" style="overflow: auto;" v-if="showPrevVersions">
          <!-- <template #cell(actions)="data" >
            <div class="demo-inline-spacing">
               <span v-b-tooltip.hover.bottom="'Raise Invoice'"><feather-icon size="21" icon="PlusSquareIcon" class="text-info"/></span> 
            </div>
          </template> -->
        </b-table>
        
      </app-timeline-item>
  
      
      <app-timeline-item variant="success">
  
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h4>Offer - SHPL/RiL/OFR/18052024/V2</h4>
          <small class="text-muted">2 hours ago</small>
        </div>
        <h6> Approved  Offer:</h6>
        <p>
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">OfferV4.pdf</span>
        </p>
        <p>Click the button below to read financial reports</p>
        <b-button
          v-b-toggle.offer-list
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="outline-primary"
        >
          Show Previous Offers
        </b-button>
        <b-collapse id="offer-list">
          <b-list-group
            flush
            class="mt-1"
          >
            <b-list-group-item
              href="#"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
                <p>
                    <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                    />
                    <span class="align-bottom">offerv1.pdf</span>
                </p> 
                <feather-icon icon="EditIcon" />
            </b-list-group-item>
            <b-list-group-item
              href="#"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
                <p>
                    <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                    />
                    <span class="align-bottom">offerv2.pdf</span>
                </p> 
                <feather-icon icon="EditIcon" />
            </b-list-group-item>
            <b-list-group-item
              href="#"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
                <p>
                    <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                    />
                    <span class="align-bottom">offerv3.pdf</span>
                </p>
                <feather-icon icon="EditIcon" />
            </b-list-group-item>
            
          </b-list-group>
        </b-collapse>
      </app-timeline-item>
  
      
      <app-timeline-item variant="warning">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h4>LOI Received - SHPL/RiL/LOI/18052024/V2</h4>
          <small class="text-muted">03:00 PM</small>
        </div>
        <h6>Accepted LOI</h6>
        <p>
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">LOIV3.pdf</span>
        </p>
        <p>Click the button below to read financial reports</p>
        <b-button
          v-b-toggle.loi-list
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="outline-primary"
        >
          Show Previous LOI's
        </b-button>
        <b-collapse id="loi-list">
          <b-list-group
            flush
            class="mt-1"
          >
            <b-list-group-item
              href="#"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
                <p>
                    <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                    />
                    <span class="align-bottom">loi_v1.pdf</span>
                </p> 
                <feather-icon icon="EditIcon" />
            </b-list-group-item>
            <b-list-group-item
              href="#"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
                <p>
                    <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                    />
                    <span class="align-bottom">loi_v2.pdf</span>
                </p> 
                <feather-icon icon="EditIcon" />
            </b-list-group-item>
            
            
          </b-list-group>
        </b-collapse>
        
      </app-timeline-item>
  
      
      <app-timeline-item variant="danger">
  
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h4>PO - SHPL/RiL/PO/18052024/V2</h4>
          <small class="text-muted">03:00 PM</small>
        </div>
        <h6>PO File</h6>
        <p>
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">PO.pdf</span>
        </p>
        
      </app-timeline-item>
  
      
    </app-timeline>
    </b-card>
  </template>
  
  <script>
  import {
    BImg, BAvatar, BMedia, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip, BCard, BRow, BCol, BTable, BSidebar,
  } from 'bootstrap-vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment'
  import InfraAddCostSheetVue from './InfraAddCostSheet.vue'
  import InfraEditCostSheetVue from './InfraEditCostSheet.vue'
  import infraCostSheetService from '@/services/infra-cost-sheet-service'
  
  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
      BImg,
      BMedia,
      BAvatar,
      BButton,
      BCollapse,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      BBadge,
      BCard, BRow, BCol, BTable,
      BSidebar,
      InfraAddCostSheetVue,
      InfraEditCostSheetVue
    },
    data() {
        return {
            inquiryId: null,
            positionFields: [
              // { key: "actions", label: 'EDIT' },
              { key: 'position', label: 'Position Name', sortable: true  },
              { key: 'quantum', label: 'quantum', sortable: true  },
              { key: 'experience', label: 'Pexperience', sortable: true  },
              { key: 'qualification', label: 'qualification', sortable: true  },
              { key: 'position_status', label: 'status', sortable: true  },
              { key: 'job_description', label: 'job description', sortable: true  },
              { key: 'tat_deputation', label: 'tat deputation', sortable: true  },
              { key: 'project_duration', label: 'project duration', sortable: true  },
              { key: 'salary_survey_curr_ctc', label: 'salary survey curr ctc', sortable: true  },
              { key: 'salary_survey_exp_ctc', label: 'salary survey exp ctc', sortable: true  },
              { key: 'hr_client', label: 'hr client', sortable: true  },
              { key: 'hr_contact_mobile', label: 'hr contact mobile', sortable: true  },
              { key: 'hr_contact_office', label: 'hr contact office', sortable: true  },
              { key: 'spoc_client', label: 'hr client', sortable: true  },
              { key: 'spoc_contact_mobile', label: 'spoc contact mobile', sortable: true  },
              { key: 'spoc_contact_office', label: 'spoc contact office', sortable: true  },
              { key: 'spoc_email', label: 'spoc email', sortable: true  },
              { key: 'working_days', label: 'working days', sortable: true  },
            ],
            costSheetFields: [
                { key: 'action', label: 'Actions' },
                { key: 'sheet_date', label: 'Date' },
                { key: 'client', label: 'Client' },
                { key: 'position', label: 'Position' },
                { key: 'qualification', label: 'Qualification' },
                { key: 'yoe', label: 'YOE' },
                { key: 'current_ctc', label: 'Current CTC' },
                // { key: 'twenty_percent', label: '20.00%' },
                { key: 'expected_ctc', label: 'Expected CTC' },
                { key: 'leave_encashment', label: 'Leave Encashment' },
                { key: 'commercial_cost', label: 'Commercial Cost' },
                { key: 'margin', label: 'Margin' },
                { key: 'margin_percent', label: '%' },
                { key: 'food_accommodation_cost', label: 'Food & Accommodation Cost' },
                { key: 'with_food_accommodation_commercial_cost', label: 'With Food & Accommodation Commercial Cost' },
                { key: 'man_months', label: 'Man Months' },
                { key: 'days', label: 'Days' },
                { key: 'days_amount', label: 'Days Amount' },
                { key: 'total_commercial_cost_for_project', label: 'Total Commercial Cost for Project' },
                { key: 'status', label: 'Status' },
                // { key: 'days_26', label: '26 Days' },
                
            ],
            items: [
                {
                date: '4/12/2024', client: 'L&T MMH', position: 'Civil Execution', qualification: 'Diploma',
                yoe: '5_8', current_ctc: '₹58,000', twenty_percent: '11,600', expected_ctc: '69,600',
                leave_encashment: '3,480', commercial_cost: '₹98,658', margin: '25,578', margin_pct: '35',
                food_accommodation_cost: '4500', with_food_accommodation_commercial_cost: '103,158',
                man_months: '', total_commercial_cost_for_project: '', days_26: '', days_24: '', days_30: ''
                },
                // Add other data rows following the same structure...
            ],
            showPrevVersions: false,
            inquiryData: {},
            costSheets: [],
            approvedCostSheet: null
        }
    },
    directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
    
    created() {
        this.inquiryId = this.$route.params.id
        this.inquiryData = JSON.parse(this.$route.query.inquiry_data)
        console.log('inquiry_data: ', this.inquiryData)

        infraCostSheetService.getAllCostSheets(this.inquiryId).then(res => {
          
          if(res.status === 200) {
            console.log('cost sheet data: ', res.data)
            this.costSheets = res.data
            const approvedSheet = res.data.filter(item => item.status === 'Approved')
            console.log('approvedSheet: ',approvedSheet)
            if(approvedSheet) {
              this.approvedCostSheet = approvedSheet
            }
          }
        })
    },
    methods: {
      showPreviousVerions() {
          this.showPrevVersions = !this.showPrevVersions
      },
      formatInquiryDate(d) {
        return moment(d).format('DD MMM YYYY')
      }
    }
  }
  </script>
   